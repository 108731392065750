import React, { useEffect, useState } from "react";
import CartHeading from "./CartHeading";
import { FaShippingFast } from "react-icons/fa";
import ups from "../Assets/Images/ups.png";
import fedex from "../Assets/Images/fedex.png";
import { Checkbox } from "@mui/material";
import { BiSolidCalculator } from "react-icons/bi";
import axios from "axios";
import { baseUrl } from "../Constant_key";
import '../Pages/home.css'
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function ShippingOptions({
    error,
    setError,
    handleFileChange,
    setSalesTaxStatus,
    salesTaxStatus,
    isTaxable,
    setIsTaxable,
    pdfBase64,
    addressId,
    setSelectedOption,
    selectedOption,
}) {
    const [serviceCharges, setServiceCharges] = useState(null);
    const navigate = useNavigate();

    const handleOptionSelect = (option) => {
        setError(null);
        setSelectedOption(option);
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios
            .post(
                `${baseUrl}api/shipping-rates`,
                { addressId },
                { headers: { Authorization: `bearer ${token}` } }
            )
            .then((res) => {
                setServiceCharges(res.data?.serviceCharges);
                setSalesTaxStatus(res.data?.salesTaxCertificateStatus);
            })
            .catch((err) => {
                if(err?.response?.status == 400) {
                    // console.log("err?.response?.data?.error?.message", err?.response?.data);
                    toast.error(err?.response?.data?.message)
                }
                if(err?.response?.status == 403) {
                    // console.log("err?.response?.data?.error?.message", err?.response?.data);
                    navigate('/login')
                    toast.error("Your session has expired. Please log in again to continue.")
                }
            });
    }, [addressId]);

    return (
        <div>
            <div>
                <CartHeading
                    icon={<FaShippingFast fontSize={14} />}
                    heading="Shopping Options"
                    caption="Choose your courier"
                />
            </div>

            <div className="shipping-options">
                <div style={{}}>
                    {serviceCharges?.map((option) => {
                        const [descriptionBeforeColon, descriptionAfterColon] =
                            option.serviceDescription.split(":");
                        return (
                            <div
                                key={option.serviceCode}
                                className={`option ${
                                    selectedOption?.serviceCode === option.serviceCode ? "selected" : ""
                                }`}
                                onClick={() => handleOptionSelect(option)}
                            >
                                <div
                                    style={{
                                        minWidth: "50px",
                                    }}
                                >
                                    <Checkbox
                                        color="primary"
                                        size="small"
                                        onChange={() => {}}
                                        checked={selectedOption?.serviceCode === option.serviceCode}
                                    />
                                </div>
                                <div className="option-details">
                                    <div
                                        style={{
                                            minWidth: "100px",
                                            flex: 1,
                                        }}
                                    >
                                        <div className="option-name">
                                            {descriptionBeforeColon}
                                        </div>
                                        <div className="option-caption">
                                            {
                                                descriptionAfterColon != undefined &&
                                                `${descriptionAfterColon}`
                                            }
                                        </div>
                                    </div>

                                    <span className="option-price">
                                        ${option.totalCharge}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div style={{ marginTop: "2rem" }}>
                <CartHeading
                    icon={<BiSolidCalculator fontSize={16} />}
                    heading={"Sales Tax Information"}
                    caption={"Please if applicable"}
                />

                {salesTaxStatus == "FALSE" ? (
                    <div style={{ marginLeft: "2.3rem" }}>
                        <div style={{ marginTop: "10px" }}>
                            <Checkbox
                                id="taxable"
                                checked={isTaxable == true}
                                onChange={() => {
                                    setError(null)
                                    setIsTaxable(true)
                                }}
                                sx={{ padding: "0px" }}
                                size="small"
                            />
                            <label
                                htmlFor="taxable"
                                style={{
                                    fontSize: "11px",
                                    fontWeight: 500,
                                }}
                            >
                                &nbsp;&nbsp;Taxable
                            </label>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "10px",
                            }}
                        >
                            <Checkbox
                                id="Non-Taxable"
                                checked={isTaxable == false}
                                onChange={() => setIsTaxable(false)}
                                sx={{ padding: "0px" }}
                                size="small"
                            />
                            <div>
                                <label
                                    htmlFor="Non-Taxable"
                                    style={{
                                        fontSize: "11px",
                                        fontWeight: 500,
                                    }}
                                >
                                    &nbsp;&nbsp;Non-Taxable
                                </label>
                                <div
                                    style={{
                                        fontSize: "9px",
                                        fontWeight: 500,
                                    }}
                                >
                                    &nbsp;&nbsp;In order to be non-taxable your
                                    company must have a resale or exemption
                                    certificate.
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <p className="uploadpdffiletext" style={{ marginTop: "1rem" }}>
                            We Already Have Sales Tax Exemption
                        </p>
                    </div>
                )}

                {isTaxable == false && (
                    <div style={{ marginTop: "1rem" }}>
                        <div>
                            <label className="uploadpdffiletext">If Not taxable then upload pdf file</label>
                        </div>
                        <input
                            accept="application/pdf"
                            style={{marginTop: '1rem'}}
                            onChange={handleFileChange}
                            type="file"
                        />

                        {pdfBase64 && (
                            <div style={{marginTop: '10px'}}>
                                <p className="uploadpdffiletext" style={{fontWeight: 'bold', color: '#4BB543'}}>PDF uploaded successfully.</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
