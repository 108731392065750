import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

const Loader = ({ loading }) => {
    return (
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            {/* <CircularProgress color="inherit" /> */}
            <img
                src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExeG9mazMwemN1MWN4NzU4ajJmZjRvcnhtc2o1eTNkcWt2czY0ejg2ZCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/MhJOCNmrgv9iifzimb/giphy.gif"
                alt="Loading GIF"
                style={{ width: "150px", height: "150px" }}
            />
        </Backdrop>
    );
};

export default Loader;
