import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    Grid,
    MenuItem,
    Select,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// import profile from "../Assets/Images/avatar.png";
import profile from "../Assets/Images/profile.webp";
import "./home.css";
import axios from "axios";
import { baseUrl } from "../Constant_key";
import { toast } from "react-toastify";

const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        "& input": {
            fontSize: "0.875rem", // Adjust font size
            padding: "6px 8px", // Adjust padding to make the height smaller
        },
    },
    "& .MuiInputAdornment-root": {
        fontSize: "0.875rem", // Adjust font size of the adornment
    },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
    "& .MuiSelect-select": {
        fontSize: "0.875rem",
        padding: "6px 8px",
        height: "10px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        // This matches the border style of the TextField
        borderColor:
            theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.23)"
                : "rgba(255, 255, 255, 0.23)",
    },
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: "0.875rem",
}));

export default function MyAccountModal({ open, handleClose }) {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [address, setAddress] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneCode, setPhoneCode] = useState(1);
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [lastUpdate, setLastUpdate] = useState("");
    const [allStates, setAllStates] = useState([]);
    const [allCities, setAllCities] = useState([]);
    const initialErrorValue = {
        phone_number: "",
        zip_code: "",
    };
    const [errors, setErrors] = useState(initialErrorValue);

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios
            .get(`${baseUrl}api/get-profile`, {
                headers: { Authorization: `bearer ${token}` },
            })
            .then((res) => {
                if (res.data.status == 200) {
                    const user = res.data.userProfile;
                    const companyProfile = res.data.companyProfile;
                    setFirstName(user?.first_name);
                    setLastName(user?.last_name);
                    setLastName(user?.last_name);
                    setEmail(user?.email);
                    setPhone(user?.phone_number);
                    setLastUpdate(user?.updated_at);
                    setCompanyName(companyProfile?.company_name);
                    setZipCode(companyProfile?.company_postal_code);
                    setAddress(companyProfile?.company_address_line1);
                    setCountry(companyProfile?.company_country);
                    setState(companyProfile?.company_state);
                    setCity(companyProfile?.company_city);
                }
            })
            .catch((err) => {
                // console.log(err)
            });
    }, []);

    function formatDate(dateString) {
        // Ensure the date string is valid before creating a Date object
        const date = new Date(dateString);

        // Check if the date object is valid
        if (isNaN(date)) {
            return "Invalid Date";
        }

        // Format the date using toLocaleDateString
        return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "2-digit",
        });
    }

    useEffect(() => {
        if (country) {
            axios
                .get(`${baseUrl}api/states/${country}`)
                .then((res) => {
                    if (res.data?.length > 0) {
                        setAllStates(res.data);
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        }
    }, [country]);

    useEffect(() => {
        if (state) {
            axios
                .get(`${baseUrl}api/cities/${state}`)
                .then((res) => {
                    if (res.data?.length > 0) {
                        setAllCities(res.data);
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        }
    }, [state]);

    const validateForm = () => {
        let isValid = true;
        let newErrors = {};
    
        // Phone (Only validate if user entered a phone number)
        const phoneRegex = /^\d{10}$/;
        if (phone && !phoneRegex.test(phone)) {
            newErrors.phone_number = "Valid 10-digit Phone number is required";
            isValid = false;
        }
    
        // Zip Code (Only validate if user entered a zip code)
        const zipRegex = /^\d{5}(-\d{4})?$/;
        if (zipCode && !zipRegex.test(zipCode)) {
            newErrors.zip_code = "Valid Zip Code is required";
            isValid = false;
        }
    
        setErrors(newErrors);
        return isValid;
    };

    const handleSaveButton = () => {
        if (validateForm()) {
            const value = {
                first_name: firstName,
                last_name: lastName,
                company_name: companyName,
                company_address_line1: address,
                company_city: city,
                company_state: state,
                company_postal_code: zipCode,
                company_country: country,
                phone_number: phone,
                // company_phone_number: phone,
            };
            const token = localStorage.getItem("token");
            axios
                .post(`${baseUrl}api/update-profile`, value, {
                    headers: { Authorization: `bearer ${token}` },
                })
                .then((res) => {
                    if (res.data.status == 200) {
                        toast?.success(res.data.message);
                        handleClose();
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        }
    };

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={handleClose}
            >
                <DialogContent
                    style={{
                        padding: "20px 35px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div>
                            <img
                                className="myaccountprofilepic"
                                src={profile}
                            />

                            {/* <div style={{backgroundColor: '#1f80c0', borderRadius: '50%', width: '20px'}}> */}
                            {/* <FaCamera /> */}
                            {/* </div> */}
                        </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <Typography
                            color="primary"
                            sx={{
                                fontSize: "15px",
                                fontWeight: 600,
                                // fontFamily: "Raleway",
                            }}
                        >
                            {firstName + " " + lastName}
                        </Typography>

                        <Typography
                            color="secondary"
                            style={{ fontSize: "13px" }}
                        >
                            {companyName}
                        </Typography>
                    </div>

                    <div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: "space-between",
                                marginTop: "10px",
                            }}
                        >
                            <span style={{ fontWeight: "bold", color: "grey" }}>
                                Edit Profile
                            </span>
                            <span style={{ fontSize: "11px", color: "grey" }}>
                                last update {formatDate(lastUpdate)}
                            </span>
                        </div>

                        <Grid container columnSpacing={4}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                {/* <Grid
                                    container
                                    style={{ marginTop: "0px" }}
                                    spacing={2}
                                > */}
                                    {/* <Grid item lg={6}> */}
                                        <div style={{marginTop: '1rem'}}>
                                            <label className="myaccountlabel">
                                                First Name
                                            </label>
                                            <div className="myaccountinputparent">
                                                <CustomTextField
                                                    autoComplete="off"
                                                    value={firstName}
                                                    onChange={(e) =>
                                                        setFirstName(
                                                            e.target.value
                                                        )
                                                    }
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                    {/* </Grid>
                                    <Grid item lg={6}> */}
                                        <div style={{ marginTop: "12px" }}>
                                            <label className="myaccountlabel">
                                                Last Name
                                            </label>
                                            <div className="myaccountinputparent">
                                                <CustomTextField
                                                    autoComplete="off"
                                                    value={lastName}
                                                    onChange={(e) =>
                                                        setLastName(
                                                            e.target.value
                                                        )
                                                    }
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                    {/* </Grid>
                                </Grid> */}

                                <div style={{ marginTop: "10px" }}>
                                    <label className="myaccountlabel">
                                        Company Name
                                    </label>
                                    <div className="myaccountinputparent">
                                        <CustomTextField
                                            autoComplete="off"
                                            value={companyName}
                                            onChange={(e) =>
                                                setCompanyName(e.target.value)
                                            }
                                            fullWidth
                                        />
                                    </div>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label className="myaccountlabel">
                                        Address
                                    </label>
                                    <div className="myaccountinputparent">
                                        <CustomTextField
                                            autoComplete="off"
                                            value={address}
                                            onChange={(e) =>
                                                setAddress(e.target.value)
                                            }
                                            fullWidth
                                        />
                                    </div>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label className="myaccountlabel">
                                        Zip Code
                                    </label>
                                    <div className="myaccountinputparent">
                                        <CustomTextField
                                            autoComplete="off"
                                            value={zipCode}
                                            onChange={(e) =>
                                                setZipCode(e.target.value)
                                            }
                                            fullWidth
                                        />
                                    </div>
                                </div>
                                {errors.zip_code && (
                                    <span className="errormessage">
                                        {errors.zip_code}
                                    </span>
                                )}
                            </Grid>

                            <Grid xs={12} sm={12} md={6} item lg={6}>
                                <div className="profileAccountEmail">
                                    <label className="myaccountlabel">
                                        Email
                                    </label>
                                    <div className="myaccountinputparent">
                                        <CustomTextField
                                            autoComplete="off"
                                            value={email}
                                            disabled
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            fullWidth
                                        />
                                    </div>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label className="myaccountlabel">
                                        Phone Number
                                    </label>
                                    <div className="myaccountinputparent"
                                        style={{ display: "flex", gap: "10px" }}
                                    >
                                        <CustomSelect
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            type="Number"
                                            value={phoneCode}
                                            onChange={(e) =>
                                                setPhoneCode(e.target.value)
                                            }
                                        >
                                            <CustomMenuItem value={1}>
                                                +1
                                            </CustomMenuItem>
                                        </CustomSelect>
                                        <CustomTextField
                                            autoComplete="off"
                                            value={phone}
                                            type="number"
                                            onChange={(e) =>
                                                setPhone(e.target.value)
                                            }
                                            fullWidth
                                        />
                                    </div>
                                    {errors.phone_number && (
                                        <span className="errormessage">
                                            {errors.phone_number}
                                        </span>
                                    )}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label className="myaccountlabel">
                                        Country
                                    </label>
                                    <div className="myaccountinputparent">
                                        <CustomSelect
                                            labelId="demo-simple-select-label"
                                            value={country}
                                            onChange={(e) =>
                                                setCountry(e.target.value)
                                            }
                                            id="demo-simple-select"
                                            fullWidth
                                        >
                                            <CustomMenuItem value={"US"}>
                                                United States
                                            </CustomMenuItem>
                                        </CustomSelect>
                                    </div>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label className="myaccountlabel">
                                        State
                                    </label>
                                    <div className="myaccountinputparent">
                                        <CustomSelect
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={state}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200, // Set the max height for the menu
                                                    },
                                                },
                                            }}
                                            onChange={(e) =>
                                                setState(e.target.value)
                                            }
                                            fullWidth
                                        >
                                            {allStates?.map((st) => (
                                                <CustomMenuItem
                                                    key={st.state_code}
                                                    value={st.state_code}
                                                >
                                                    {st.state_name}
                                                </CustomMenuItem>
                                            ))}
                                        </CustomSelect>
                                    </div>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label className="myaccountlabel">
                                        City
                                    </label>
                                    <div className="myaccountinputparent">
                                        <CustomSelect
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200, // Set the max height for the menu
                                                    },
                                                },
                                            }}
                                            value={city}
                                            onChange={(e) =>
                                                setCity(e.target.value)
                                            }
                                            fullWidth
                                        >
                                            {allCities?.map((st) => (
                                                <CustomMenuItem
                                                    key={st.city_name}
                                                    value={st.city_name}
                                                >
                                                    {st.city_name}
                                                </CustomMenuItem>
                                            ))}
                                        </CustomSelect>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "2rem",
                            }}
                        >
                            <Button className="cancelBtn" onClick={handleClose}>
                                CANCEL
                            </Button>
                            <Button
                                className="saveBtn"
                                onClick={handleSaveButton}
                            >
                                SAVE
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
