import React, { useState } from "react";
import "../Pages/home.css";
import product from "../Assets/Images/Product 3.png";
import Trash from "../Assets/Images/Trash.png";
import { Grid, Button, TextField } from "@mui/material";
import { RiEditBoxLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa6";

export default function CartItem({
    item,
    handleDeleteCartItem,
    handleUpdateCartItem,
}) {
    const navigate = useNavigate();
    const [showEditInput, setShowEditInput] = useState(false);
    const [editedQuantity, setEditedQuantity] = useState(item.quantity);

    const handleEdit = () => {
        setShowEditInput(true);
    };

    const handleQuantityChange = (e) => {
        setEditedQuantity(e.target.value);
    };

    const handleUpdate = () => {
        // Call the API to update the cart
        handleUpdateCartItem(item.price, item.part_number, editedQuantity);
        setShowEditInput(false);
    };

    const handlePartClick = (item) => {
        navigate(`/part/${encodeURIComponent(item?.part_number)}`, {
            state: { quantity: item?.quantity },
        });
    };

    return (
        <div style={{ marginTop: "1rem" }} className="cartitemborder">
            <Grid container rowSpacing={2}>
                <Grid
                    item
                    md={6}
                    sm={6}
                    xs={12}
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div
                        onClick={() => handlePartClick(item)}
                        className="cartitemimageParent"
                    >
                        <img
                            className="cartitemimage"
                            src={product}
                            alt="Product"
                        />
                    </div>
                    <div
                        onClick={() => handlePartClick(item)}
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                    >
                        <div style={{ fontSize: "10px", color: "#1F80C0" }}>
                            CUI Devices
                        </div>
                        <div style={{ fontSize: "11px", fontWeight: 500 }}>
                            {item?.part_number}
                        </div>
                        <div style={{ fontSize: "10px" }}>
                            SJ2-35853B-SMT-TR
                        </div>
                    </div>
                </Grid>

                <Grid
                    item
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                    xs={4}
                    md={3}
                    sm={3}
                >
                    <div style={{ fontSize: "12px", color: "#1F80C0" }}>
                        Quantity
                    </div>
                    {showEditInput ? (
                        <div
                            style={{
                                display: "flex",
                                marginTop: "5px",
                                gap: "10px",
                                alignItems: "center",
                            }}
                        >
                            <TextField
                                type="number"
                                value={editedQuantity}
                                onChange={handleQuantityChange}
                                size="small"
                                style={{ width: "5rem" }}
                            />
                            <Button
                                onClick={handleUpdate}
                                variant="contained"
                                size="small"
                                className="correctwrongbutton"
                            >
                                <FaCheck />
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowEditInput(false);
                                    setEditedQuantity(item.quantity);
                                }}
                                variant="outlined"
                                size="small"
                                className="correctwrongbutton"
                            >
                                <RxCross2 />
                            </Button>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                marginTop: "5px",
                                gap: "10px",
                                alignItems: "center",
                            }}
                        >
                            <RiEditBoxLine
                                onClick={handleEdit}
                                fontSize={16}
                                style={{ cursor: "pointer" }}
                            />
                            <span
                                style={{ fontSize: "15px", fontWeight: "bold" }}
                            >
                                {item?.quantity}
                            </span>
                        </div>
                    )}
                </Grid>
                <Grid
                    item
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                    xs={4}
                    md={2}
                    sm={2}
                >
                    <div style={{ fontSize: "12px", color: "#1F80C0" }}>
                        Total Price
                    </div>
                    <span
                        style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            marginTop: "5px",
                        }}
                    >
                        ${(item?.quantity * item?.price).toFixed(2)}
                    </span>
                </Grid>
                <Grid
                    item
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    xs={4}
                    md={1}
                    sm={1}
                >
                    <img
                        onClick={() => handleDeleteCartItem(item)}
                        style={{ width: "20px", cursor: "pointer" }}
                        src={Trash}
                        alt="Delete"
                    />
                </Grid>
            </Grid>
        </div>
    );
}
